import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaHeart } from 'react-icons/fa';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { updateFavoriteOrderLocal } from '../../shared/redux/slices/FavoriteSlice';
import FavoriteItem from './FavoriteItem';
import './Favorite.scss';

const Favorite = ({ customDraggableHandle, onCustomHandleRender }) => {
  const favorites = useSelector(state => state.favorite.favorites);
  const dispatch = useDispatch();

  useEffect(() => {
    if (onCustomHandleRender) {
      onCustomHandleRender();
    }
  }, []);

  const moveItem = (fromIndex, toIndex) => {
    const updatedFavorites = [...favorites];
    const [movedItem] = updatedFavorites.splice(fromIndex, 1);
    updatedFavorites.splice(toIndex, 0, movedItem);
    dispatch(updateFavoriteOrderLocal(updatedFavorites));
  };

  return (
    <>
      <div className="favorite-top-header">
        <div className="favorite-title">
          <FaHeart className="heart-icon" />
        </div>
        <div className={`${customDraggableHandle} drag-handle-container`} style={{
          flex: 1,
          height: '30px', // 명시적인 높이 지정
          minHeight: '30px', // 최소 높이 보장
          display: 'flex',
          alignItems: 'center', 
          justifyContent: 'flex-end',
          cursor: 'move',
          position: 'relative', // 위치 지정
          zIndex: 1 // 다른 요소들보다 위에 표시
        }}>
          {/* 커스텀 그리드 핸들 */}
        </div>
        <div className="favorite-tabs">
          <button>기본</button>
          {/* 추가 탭은 여기에 */}
        </div>
      </div>
      <div className="divider"></div>
      <DndProvider backend={HTML5Backend}>
        <div className="favorite-container" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div className="favorite-list" style={{ flexGrow: 1, overflowY: 'auto' }}>
            {favorites.map((item, index) => (
              <FavoriteItem key={item.symbol} item={item} index={index} moveItem={moveItem} />
            ))}
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default Favorite;
