import React from 'react';

const isMobile = () => {
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|SM-F|fold/i.test(navigator.userAgent);
};

const MobileWarning = () => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      textAlign: 'center'
    }}>
      <h1>모바일 접속 불가</h1>
      <p>죄송합니다. 이 웹사이트는 현재 모바일 기기에서 지원되지 않습니다.</p>
      <p>데스크톱 또는 태블릿으로 접속해 주세요.</p>
    </div>
  );
};

const AccessControl = ({ children }) => {
  if (isMobile()) {
    return <MobileWarning />;
  }
  return children;
};

export default AccessControl;
