import React, { useRef, useEffect, useState } from 'react';
import './Info.scss';
import { FaYoutube, FaHeart } from 'react-icons/fa'; // YouTube 아이콘을 위해 react-icons 사용

const Info = ({ customDraggableHandle }) => {
  const containerRef = useRef(null);
  const [thumbnailSize, setThumbnailSize] = useState(60);
  const [isFavorite, setIsFavorite] = useState(false);
  const [priceChange, setPriceChange] = useState(2.5); // 예시 값
  const [priceChangeRange, setPriceChangeRange] = useState({ high: 3.2, low: -1.8 }); // 예시 값
  const [currentPrice, setCurrentPrice] = useState(23700); // 임시 현재 가격

  const candleHeight = 30; // 캔들의 전체 높이 (픽셀)

  const getCandlePosition = () => {
    const totalRange = priceChangeRange.high - priceChangeRange.low;
    const bodyHeight = (Math.abs(priceChange) / totalRange) * candleHeight * 0.6; // 몸통 높이를 60%로 제한
    const wickTop = ((priceChangeRange.high - Math.max(priceChange, 0)) / totalRange) * candleHeight;
    const wickBottom = ((Math.min(priceChange, 0) - priceChangeRange.low) / totalRange) * candleHeight;

    return {
      wickTop,
      wickHeight: candleHeight,
      bodyTop: priceChange >= 0 ? wickTop : wickTop + (candleHeight - bodyHeight) / 2,
      bodyHeight
    };
  };

  const candlePosition = getCandlePosition();

  // 컨테이너 크기에 따라 썸네일 크기 조정
  useEffect(() => {
    const updateThumbnailSize = () => {
      if (containerRef.current) {
        const height = containerRef.current.clientHeight;
        // setThumbnailSize(Math.max(height * 0.8, 40)); // 최소 크기 40px
        setThumbnailSize(60); // 최소 크기 40px
      }
    };

    updateThumbnailSize();
    window.addEventListener('resize', updateThumbnailSize);

    return () => window.removeEventListener('resize', updateThumbnailSize);
  }, []);

  return (
    <div className="info-container" ref={containerRef}>
      <div className="info-content">
        <div className="left-content">
          {/* 동적 크기 조정을 위한 썸네일 래퍼 */}
          <div
            className="stock-thumbnail-wrapper"
            style={{
              width: `${thumbnailSize}px`,
              height: `${thumbnailSize}px`,
              borderRadius: `${thumbnailSize / 2}px`
            }}
          >
            <div
              className="stock-thumbnail"
              style={{
                width: `${thumbnailSize - 4}px`,
                height: `${thumbnailSize - 4}px`
              }}
            ></div>
          </div>
          <div className="stock-info">
            {/* 주식 정보 표시 */}
            <div className="stock-name-row">
              <h1 className="stock-name">ItSUB 잇섭</h1>
              <FaYoutube className="platform-icon" />
              <span className="stock-ticker">ITSUB</span>
            </div>
            {/* 해시태그 표시 */}
            <div className="hashtags">
              <span className="hashtag">#IT</span>
              <span className="hashtag">#돼지</span>
              <span className="hashtag">#200만</span>
            </div>
          </div>
        </div>
        <div className="right-content">

          {/* 현재 가격 표시 */}
          <div className="current-price" style={{
            fontSize: `${thumbnailSize * 0.6}px`,
            marginRight: '10px',
            fontWeight: 'bold',
            color: 'red'
          }}>
            {currentPrice.toLocaleString()}
          </div>
          {/* 가격 변동 퍼센트 표시 */}
          <div
            className="price-change-percentage"
            style={{
              color: priceChange >= 0 ? 'red' : 'blue',
              fontSize: `${thumbnailSize * 0.5}px`,
              width: 'auto',
              marginRight: '10px',
              whiteSpace: 'nowrap',
              display: 'inline-block'
            }}
          >
            {priceChange >= 0 ? '+' : ''}{priceChange.toFixed(1)}%
          </div>

          {/* 가격 변동을 표시하는 캔들 차트와 퍼센트 */}
          <div className="price-change-container">
            <div className="candle-wrapper" style={{ height: `${candleHeight}px`, marginRight: '5px' }}>
              {/* 캔들의 심지 (전체 가격 변동 범위) */}
              <div
                className="candle-wick"
                style={{
                  backgroundColor: priceChange >= 0 ? 'red' : 'blue',
                  top: 0,
                  height: `${candleHeight}px`,
                  width: '2px',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              ></div>
              {/* 캔들의 몸통 (현재 가격 변동) */}
              <div
                className="candle-body"
                style={{
                  backgroundColor: priceChange >= 0 ? 'red' : 'blue',
                  top: `${candlePosition.bodyTop}px`,
                  height: `${candlePosition.bodyHeight}px`,
                  width: '8px',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              ></div>
            </div>

          </div>
          {/* 즐겨찾기 하트 토글 버튼 */}
          <button
            className={`favorite-button ${isFavorite ? 'active' : ''}`}
            onClick={() => setIsFavorite(!isFavorite)}
            style={{
              height: `${thumbnailSize * 0.6}px`,
              width: `${thumbnailSize * 0.6}px`,
              borderRadius: `${thumbnailSize * 0.15}px`
            }}
          >
            <FaHeart style={{ fontSize: `${thumbnailSize * 0.3}px` }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Info;
