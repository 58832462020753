import React from 'react';
import { Modal } from 'antd';
import Tree from './Tree';
import treeMapData from './Data';
import './Treemap.scss';

const Treemap = ({ isVisible, onClose }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width="80vw"
      styles={{
        body: {
          height: '80vh',
          padding: 0,
        }
      }}
      className="treemap-modal"
    >
      <div className="treemap-container">
        <Tree data={treeMapData} />
      </div>
    </Modal>
  );
};

export default Treemap;
