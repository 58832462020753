import React, { useState, useEffect } from 'react';
import Tab1 from './tabs/PositionList';
import Tab2 from './tabs/TradingList';
import './Position.scss';

const Position = ({ customDraggableHandle, onCustomHandleRender }) => {
  const [activeTab, setActiveTab] = useState('최애 리스트');

  useEffect(() => {
    if (onCustomHandleRender) {
      onCustomHandleRender();
    }
  }, []);

  return (
    <div className="position-container">
      <div className="position-top-header">
        <div className="position-tabs">
          <button className={activeTab === '최애 리스트' ? 'active' : ''} onClick={() => setActiveTab('최애 리스트')}>최애 리스트</button>
          <button className={activeTab === '거래내역' ? 'active' : ''} onClick={() => setActiveTab('거래내역')}>거래내역</button>
        </div>
        <div className={`${customDraggableHandle} drag-handle-container`} style={{
          flex: 1,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          cursor: 'move',
          // backgroundImage: 'linear-gradient(45deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 75%, transparent 75%, transparent)',
          backgroundSize: '50px 50px',
        }}>
          {/* <span className="drag-handle-text">{'<포지션>'}</span> */}
        </div>
      </div>
      {activeTab === '최애 리스트' ? <Tab1 /> : <Tab2 />}
    </div>
  );
};

export default Position;
