// layouts.js
const Layouts = {
  xxl: [
    { i: 'a', x: 1, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    // { i: 'c', x: 0, y: 3, w: 28, h: 17 },  // Orderbook용 레이아웃
    // { i: 'd', x: 28, y: 3, w: 10, h: 17 }, 
    { i: 'c', x: 0, y: 3, w: 29, h: 17 },  // 가로 크기를 29로 변경
    { i: 'd', x: 29, y: 3, w: 11, h: 17 }, // 가로 크기를 10으로 변경, x 위치 조정
    { i: 'e', x: 40, y: 3, w: 8, h: 17 },  // 가로 크기를 9로 변경
    { i: 'f', x: 0, y: 20, w: 40, h: 7 },
    { i: 'g', x: 40, y: 20, w: 8, h: 7 }
  ],
  xl: [
    { i: 'a', x: 1, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 20, h: 16 },  // 가로 크기를 29로 변경
    { i: 'd', x: 20, y: 3, w: 11, h: 16 }, // 가로 크기를 10으로 변경, x 위치 조정
    { i: 'e', x: 40, y: 3, w: 9, h: 16 },  // 가로 크기를 9로 변경
    { i: 'f', x: 0, y: 19, w: 31, h: 8 },
    { i: 'g', x: 31, y: 3, w: 9, h: 8 }
  ],
  lg: [
    { i: 'a', x: 0, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 23, h: 12 },  // 가로 크기를 29로 변경
    { i: 'd', x: 0, y: 20, w: 17, h: 7 }, // 가로 크기를 10으로 변경, x 위치 조정
    { i: 'e', x: 23, y: 3, w: 9, h: 12 },  // 가로 크기를 9로 변경
    { i: 'f', x: 0, y: 15, w: 32, h: 5 },
    { i: 'g', x: 17, y: 20, w: 15, h: 7 }
  ],
  md: [
    // md 이하의 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 17, h: 12 },  // 가로 크기를 29로 변경
    { i: 'd', x: 0, y: 21, w: 13, h: 6 }, // 가로 크기를 10으로 변경, x 위치 조정
    { i: 'e', x: 17, y: 3, w: 7, h: 12 },  // 가로 크기를 9로 변경
    { i: 'f', x: 0, y: 15, w: 24, h: 6 },
    { i: 'g', x: 13, y: 20, w: 11, h: 6 }
  ],
  sm: [
    // sm 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 16, h: 2 },
    { i: 'b', x: 0, y: 2, w: 16, h: 1 },
    { i: 'c', x: 0, y: 3, w: 16, h: 9 },
    { i: 'd', x: 0, y: 12, w: 9, h: 7 },
    { i: 'e', x: 9, y: 12, w: 7, h: 11 },
    { i: 'f', x: 0, y: 23, w: 16, h: 4 },
    { i: 'g', x: 0, y: 19, w: 9, h: 4 },
  ],
  xs: [
    // xs 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 16, h: 2 },
    { i: 'b', x: 0, y: 2, w: 16, h: 1 },
    { i: 'c', x: 0, y: 3, w: 12, h: 8 },
    { i: 'd', x: 0, y: 11, w: 7, h: 6 },
    { i: 'e', x: 7, y: 11, w: 5, h: 12 },
    { i: 'f', x: 0, y: 23, w: 12, h: 4 },
    { i: 'g', x: 0, y: 17, w: 7, h: 6 },
  ],
  xxs: [
    // xxs 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 8, h: 6 },
    { i: 'd', x: 0, y: 19, w: 8, h: 6 },
    { i: 'e', x: 0, y: 9, w: 8, h: 10 },
    { i: 'f', x: 0, y: 25, w: 8, h: 5 },
    { i: 'g', x: 0, y: 30, w: 8, h: 6 },
  ],
};

export default Layouts;