import React, { useState, useRef } from 'react';
import { Row, Col, Statistic } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { HiLightningBolt } from "react-icons/hi";
import CountUp from 'react-countup';
import './Account.scss';

const Account = () => {
  const accountInfo = {
    totalValue: 5000000,
    cashBalance: 1000000,
    investedValue: 4000000,
    portfolioPerformance: 12.5,
  };

  const formatter = (value) => (
    <CountUp end={value} separator="," />
  );

  return (
    <div className="account-container">
      <div className="account-details">
        <Row gutter={[8, 8]} className="account-stats">
          <Col span={12}>
            <div className="stat-item">
              <Statistic
                title="수익률"
                value={accountInfo.portfolioPerformance}
                precision={2}
                valueStyle={{ 
                  color: accountInfo.portfolioPerformance >= 0 ? '#3f8600' : '#cf1322',
                  fontSize: '1em'
                }}
                prefix={accountInfo.portfolioPerformance >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                suffix="%"
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="stat-item">
              <Statistic
                title="총 자산"
                value={accountInfo.totalValue}
                formatter={formatter}
                valueStyle={{ fontSize: '1em' }}
                suffix={<HiLightningBolt style={{ color: 'red' }} />}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="stat-item">
              <Statistic
                title="현금 잔액"
                value={accountInfo.cashBalance}
                formatter={formatter}
                valueStyle={{ fontSize: '1em' }}
                suffix={<HiLightningBolt style={{ color: 'red' }} />}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="stat-item">
              <Statistic
                title="투자 금액"
                value={accountInfo.investedValue}
                formatter={formatter}
                valueStyle={{ fontSize: '1em' }}
                suffix={<HiLightningBolt style={{ color: 'red' }} />}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Account;
