import React, { useEffect, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
import './Sign.scss';

// 더미 데이터
const dummyStocks = [
  { name: 'AAPL', price: 1, change: 2.5 },
  { name: 'GOOOOGL', price: 2, change: -1.2 },
  { name: 'MSFT', price: 3, change: 0.8 },
  { name: 'AMZN', price: 4, change: -0.5 },
  { name: 'FB', price: 5, change: 1.7 },
  { name: 'AAPL', price: 6, change: 2.5 },
  { name: 'GOOGL', price: 7, change: -1.2 },
  { name: 'MSFT', price: 8, change: 0.8 },
  { name: 'AMZN', price: 9, change: -0.5 },
  { name: 'FB', price: 10, change: 1.7 },
  { name: 'AAPL', price: 11, change: 2.5 },
  { name: 'GOOGL', price: 12, change: -1.2 },
  { name: 'MSFT', price: 13, change: 0.8 }
  // 더 많은 더미 데이터 추가...
];

// 전광판 컴포넌트
const Sign = () => {
  // Redux store에서 주식 데이터 가져오기 (주석 처리)
  // const { stocks } = useSelector((state) => state.market);
  
  // 화면에 표시할 주식 데이터 상태
  const [displayStocks, setDisplayStocks] = useState([]);
  // 전광판 컨테이너에 대한 ref
  const containerRef = useRef(null);
  // 실제로 움직이는 요소에 대한 ref
  const tickerRef = useRef(null);
  
  // 픽셀당 물리적 거리 계산 (1인치 = 96픽셀, 1인치 = 2.54cm)
  const pixelsPerCm = 96 / 2.54;
  // 초당 이동할 cm 설정 (예: 5cm/s)
  const cmPerSecond = 2;
  
  // 주식 데이터가 변경될 때마다 화면에 표시할 데이터 업데이트
  useEffect(() => {
    // 더미 데이터 사용
    setDisplayStocks(dummyStocks);
  }, []);

  // 전광판 애니메이션 효과 구현
  useEffect(() => {
    const container = containerRef.current;
    const ticker = tickerRef.current;
    
    if (container && ticker) {
      let animationFrameId;
      let position = 0;
      let lastTimestamp = null;

      // 주식 정보를 왼쪽으로 이동시키는 함수
      const moveStocks = (timestamp) => {
        if (!lastTimestamp) {
          lastTimestamp = timestamp;
        }
        
        // 경과 시간 계산 (초 단위)
        const elapsed = (timestamp - lastTimestamp) / 1000;
        // 이동할 픽셀 수 계산
        const pixelsToMove = cmPerSecond * pixelsPerCm * elapsed;
        
        position -= pixelsToMove;
        ticker.style.transform = `translateX(${position}px)`;

        const firstItem = ticker.firstElementChild;
        if (firstItem && Math.abs(position) >= firstItem.offsetWidth) {
          position += firstItem.offsetWidth;
          ticker.appendChild(firstItem.cloneNode(true));
          ticker.removeChild(firstItem);
          ticker.style.transform = `translateX(${position}px)`;
        }

        lastTimestamp = timestamp;
        animationFrameId = requestAnimationFrame(moveStocks);
      };

      const setTickerWidth = () => {
        const totalWidth = displayStocks.reduce((acc, _, index) => {
          const item = ticker.children[index];
          return acc + (item ? item.offsetWidth : 0);
        }, 0);
        ticker.style.width = `${totalWidth * 2}px`;
      };

      setTickerWidth();
      animationFrameId = requestAnimationFrame(moveStocks);

      window.addEventListener('resize', setTickerWidth);

      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        window.removeEventListener('resize', setTickerWidth);
      };
    }
  }, [displayStocks]);

  // 전광판 더링
  return (
    <div className="sign-container" ref={containerRef}>
      <div className="sign-ticker" ref={tickerRef}>
        {displayStocks.concat(displayStocks).map((stock, index) => (
          <div
            key={`${stock.name}-${index}`}
            className={`stock-item ${stock.change >= 0 ? 'positive' : 'negative'}`}
          >
            <span className="stock-name">{stock.name}</span>
            <span className="stock-price">{stock.price.toFixed(2)}</span>
            <span className="stock-change">
              {stock.change >= 0 ? '+' : ''}{stock.change.toFixed(2)}%
            </span>
            <span className="stock-arrow">
              {stock.change >= 0 ? '▲' : '▼'}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sign;
