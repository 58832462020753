import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { marketData, updateMarketData } from '../../../widgets/Sign/data';

// 실시간 시장 데이터를 가져오는 비동기 액션
export const fetchMarketData = createAsyncThunk(
  'market/fetchMarketData',
  async () => {
    // 실제 API 호출 대신 더미 데이터 사용
    return updateMarketData();
  }
);

const marketSlice = createSlice({
  name: 'market',
  initialState: {
    stocks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMarketData.fulfilled, (state, action) => {
        state.loading = false;
        state.stocks = action.payload;
      })
      .addCase(fetchMarketData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default marketSlice.reducer;
