import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPositions, updatePositionsData } from '../../../shared/redux/slices/PositionsSlice';
import PositionItem from './PositionListItem';

const PositionList = () => {
  const dispatch = useDispatch();
  const { positions, isLoading, error } = useSelector((state) => state.positions);

  useEffect(() => {
    dispatch(fetchPositions());
    const interval = setInterval(() => dispatch(updatePositionsData()), 500);
    return () => clearInterval(interval);
  }, [dispatch]);

  const sortedPositions = useMemo(() => {
    return [...positions].sort((a, b) => b.evaluationAmount - a.evaluationAmount);
  }, [positions]);

  if (isLoading) return <div>로딩 중...</div>;
  if (error) return <div>오류: {error}</div>;

  return (
    <>
      <div className="position-list-header">
        <span>비중</span>
        <span>이름</span>
        <span>평가액</span>
        <span>평가손익</span>
        <span>매수금액</span>
        <span>매수단가</span>
        <span>현재가</span>
        <span>수량</span>
        <span>수익률</span>
      </div>
      <div className="position-list">
        {sortedPositions.map((pos, index) => (
          <PositionItem 
            key={pos.symbol} 
            position={pos} 
            style={{ height: index === 0 ? '60px' : '30px', fontSize: index === 0 ? '1.2rem' : '1rem', fontWeight: index === 0 ? 'bold' : 'normal' }}
          />
        ))}
      </div>
    </>
  );
};

export default PositionList;
