import React, { useState, useEffect } from 'react';
import './CreatorProfile.scss';

const CreatorProfile = ({ data, isRegistered }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={`creator-profile ${isVisible ? 'visible' : ''}`}>
      <img src={data.profileImage} alt={data.name} className="profile-image" />
      <h2>{isRegistered ? '이미 등록되어 있습니다!' : '이 크리에이터가 맞나요?'}</h2>
      <p>{data.name}</p>
    </div>
  );
};

export default CreatorProfile;

