/* eslint-disable*/
import './App.scss';
import { Routes, Route } from 'react-router-dom'
import AccessControl from '../modules/AccessControl/AccessControl';
import Main from '../modules/Main/Main';
import Test from '../modules/Test/Test';

function App() {
  return (
    <AccessControl>
      <div className="App">
        <Routes>
          <Route path="/" element={<Test />} />
          <Route path="/test" element={<Main />} />
        </Routes>
      </div>
    </AccessControl>
  );
}

export default App;
