import React, { useState, useEffect, useRef } from 'react';
import './Main.scss';

import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Grid from '../Grid/Grid'

function Main() {
    const [contentHeight, setContentHeight] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        const updateHeight = () => {
            if (contentRef.current) {
                setContentHeight(contentRef.current.offsetHeight);
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const toggleOverlay = () => {
        setShowOverlay(!showOverlay);
    };

    return (
        <div className='main'>
            <div className='Navbar'>
                <Navbar />
            </div>
            
            <div className='content' ref={contentRef}>
                <Grid />
                {showOverlay && (
                    <div className='height-overlay'>
                        <span>{contentHeight}px</span>
                    </div>
                )}
            </div>

            <div className='Footer'>
                <Footer />
            </div>
            <button className='toggle-overlay' onClick={toggleOverlay}>
                {showOverlay ? '오버레이 숨기기' : '오버레이 표시'}
            </button>
        </div>
    )
}

export default Main