import React, { useState, useEffect } from "react";
import { Select, Button, Input, Divider, Segmented, Switch, Row, Col, Statistic } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { HiLightningBolt } from "react-icons/hi";
import CountUp from 'react-countup';
import './Trading.scss';

const { Option } = Select;

const formatter = (value) => (
  <CountUp end={value} separator="," />
);

const Trading = ({ customDraggableHandle, onCustomHandleRender }) => {
  const [orderType, setOrderType] = useState('예약 주문');
  const [priceType, setPriceType] = useState('지정가');
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState('');
  const [quantityPercentage, setQuantityPercentage] = useState(null);

  useEffect(() => {
    if (onCustomHandleRender) {
      onCustomHandleRender();
    }
  }, [onCustomHandleRender]);

  const handlePriceChange = (value) => {
    const numValue = Math.max(0, Number(value));
    setPrice(numValue);
  };

  const handleQuantityChange = (value) => {
    const numValue = Math.max(0, Number(value));
    setQuantity(numValue);
    setQuantityPercentage(null);
  };

  const handleWheel = (e, type) => {
    e.preventDefault();
    if (type === 'price') {
      setPrice(prev => Math.max(0, prev + (e.deltaY > 0 ? -100 : 100)));
    } else if (type === 'quantity') {
      setQuantity(prev => Math.max(0, Number(prev) + (e.deltaY > 0 ? -1 : 1)));
    }
  };

  const totalAmount = price * quantity;

  return (
    <div className="trading-container">
      <div className="trading-header">
        <FaMoneyCheckDollar size={24} color="green" />
        {/* <div className="trading-title"> 주문</div> */}
        <div className={`${customDraggableHandle} drag-handle-container`} style={{
          flex: 1,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          cursor: 'move',
          backgroundSize: '50px 50px',
        }} />
      </div>

      <Divider style={{ margin: '5px 10px 10px 0px' }} />

      <div className="trading-form">
        {/* <div className="form-item">
          <label>주문 유형</label>
          <Select defaultValue={orderType} onChange={setOrderType} style={{ width: '100%' }}>
            <Option value="예약 주문">예약 주문</Option>
            <Option value="조건 주문">조건 주문</Option>
          </Select>
        </div> */}

        <div className="form-item">
          <label>구매 가격</label>
          <Switch
            checked={priceType === "시장가"} 
            onChange={(checked) => setPriceType(checked ? "시장가" : "지정가")}
            checkedChildren="시장가"
            unCheckedChildren="지정가"
            style={{ 
              backgroundColor: priceType === "시장가" ? '#1890ff' : '#52c41a',
              width: '80px'  // 고정 너비 추가
            }}
          />
        </div>

        <div className="form-item">
          <label>가격</label>
          <div className="price-input">
            <Button icon={<MinusOutlined />} onClick={() => handlePriceChange(Math.max(0, price - 100))} />
            <Input
              value={price}
              onChange={(e) => handlePriceChange(e.target.value)}
              onWheel={(e) => handleWheel(e, 'price')}
              style={{ margin: '0 8px' }}
              suffix={<HiLightningBolt style={{ color: 'red' }} />}
            />
            <Button icon={<PlusOutlined />} onClick={() => handlePriceChange(price + 100)} />
          </div>
        </div>

        <div className="form-item">
          <label>수량</label>
          <div className="quantity-input">
            <Button icon={<MinusOutlined />} onClick={() => handleQuantityChange(Math.max(0, Number(quantity) - 1))} />
            <Input
              value={quantity}
              onChange={(e) => handleQuantityChange(e.target.value)}
              onWheel={(e) => handleWheel(e, 'quantity')}
              style={{ margin: '0 8px' }}
              placeholder="수량 입력"
            />
            <Button icon={<PlusOutlined />} onClick={() => handleQuantityChange(Number(quantity) + 1)} />
          </div>
        </div>

        <div className="form-item">
          <Segmented
            options={[
              { label: '10%', value: 10 },
              { label: '25%', value: 25 },
              { label: '50%', value: 50 },
              { label: '75%', value: 75 },
              { label: '최대', value: 100 }
            ]}
            value={quantityPercentage}
            onChange={setQuantityPercentage}
          />
        </div>

        <Divider />

        <Row gutter={16} className="trading-stats">
          <Col span={12}>
            <Statistic 
              title="구매 가능" 
              value={1000000} 
              formatter={formatter}
              suffix={<HiLightningBolt style={{ color: 'red' }} />}
            />
          </Col>
          <Col span={12}>
            <Statistic 
              title="총 금액" 
              value={totalAmount} 
              formatter={formatter}
              suffix={<HiLightningBolt style={{ color: 'red' }} />}
            />
          </Col>
        </Row>

        <div className="trading-buttons">
          <Button type="primary" danger>매수</Button>
          <Button type="primary" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>매도</Button>
        </div>
      </div>
    </div>
  );
};

export default Trading;
