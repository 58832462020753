import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dummyPositions, updatePositions } from '../../../widgets/Position/data';

export const fetchPositions = createAsyncThunk(
  'positions/fetchPositions',
  async () => {
    return dummyPositions;
  }
);

// createAsyncThunk는 비동기 작업을 처리하는 함수를 만듭니다.
// 이 함수는 시간이 걸리는 작업을 수행하고, 작업이 끝나면 결과를 Redux에 알려줍니다.
// 예를 들어, 서버에서 데이터를 가져오는 것과 같은 작업에 사용할 수 있습니다.
export const updatePositionsData = createAsyncThunk(
  'positions/updatePositionsData',
  async () => {
    return updatePositions();
  }
);

// 포지션 데이터를 관리하는 Redux 슬라이스 생성
const positionsSlice = createSlice({
  name: 'positions',
  initialState: {
    positions: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 포지션 데이터 로딩 시작 시 상태 업데이트
      .addCase(fetchPositions.pending, (state) => {
        state.isLoading = true;
      })
      // 포지션 데이터 로딩 성공 시 상태 업데이트
      .addCase(fetchPositions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.positions = action.payload;
      })
      // 포지션 데이터 로딩 실패 시 에러 상태 업데이트
      .addCase(fetchPositions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // 포지션 데이터 업데이트 성공 시 상태 갱신
      .addCase(updatePositionsData.fulfilled, (state, action) => {
        state.positions = action.payload;
      });
  },
});

export default positionsSlice.reducer;