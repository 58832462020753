import { createSlice } from '@reduxjs/toolkit';

const GridSlice = createSlice({
  name: 'grid',
  initialState: {
    containerSize: { width: 0, height: 0 },
    layout: [],
  },
  reducers: {
    setContainerSize: (state, action) => {
      state.containerSize = action.payload;
    },
    setLayout: (state, action) => {
      state.layout = action.payload;
    },
  },
});

export const { setContainerSize, setLayout } = GridSlice.actions;
export default GridSlice.reducer;
