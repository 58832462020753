import React, { useState, useEffect, useRef } from 'react';
import { FaUpload } from 'react-icons/fa';
import './FirstPage.scss';

const FirstPage = ({ value, onChange, onSearch }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setIsExpanded(true);
    inputRef.current.focus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={handleSubmit} className="search-form" style={{ width: '100%', height: '100%' }}>
      <div className="add-logo">
        <span className="add-logo-text">uStock</span>
        <span className="add-beta-tag">beta</span>
      </div>
      <div className="large-text">크리에이터 등록</div>
      <div className="small-text">크리에이터의 이름을 검색하거나</div>
      <div className="small-text">주소를 입력해 주세요.</div>

      <div className="search-input-container">
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="youtube.com/@~ 또는 x.com/~ 또는 instagram.com/~"
          className="search-input"
        />
      </div>
      <button type="submit" className="add-search-button">
        <FaUpload size={24} color="white" />
      </button>
    </form>
  );
};

export default FirstPage;
