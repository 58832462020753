import React from 'react';
import { Spin } from 'antd';
import './LoadingIndicator.scss';

const LoadingIndicator = () => {
  return (
    <div className="loading-container">
      <Spin size="large" />
    </div>
  );
};

export default LoadingIndicator;

