import React, { useRef, useEffect, useState } from "react";
import { Select, Space, Segmented } from 'antd';
import './Chart.scss';
import ChartComponent from "./ChartComponent/CahrtComponent";
import initialData from "./ChartComponent/Data";
import { PiChartLineUpFill } from "react-icons/pi";

// 커스텀 핸들 기능이 추가된 Chart 컴포넌트
function Chart({ customDraggableHandle, onCustomHandleRender }) {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const [chartType, setChartType] = useState('캔들');

    useEffect(() => {
        if (!containerRef.current) return;

        // 커스텀 핸들 렌더링 콜백
        if (onCustomHandleRender) {
            onCustomHandleRender();
        }

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setContainerSize({ width, height });
            }
        });

        resizeObserver.observe(containerRef.current);

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [onCustomHandleRender]);

    return (
        <div ref={containerRef} className="chart-container">
            <div className="chart-options">
                <PiChartLineUpFill className="chart-title" style={{ color: '#2E71EF', fontSize: '1.8em' }} />
                <div className={`${customDraggableHandle} drag-handle-container`} style={{
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    cursor: 'move',
                    backgroundSize: '50px 50px',
                }}>
                </div>
                <Space className="chart-controls">
                    <Select
                        defaultValue="1분"
                        style={{ width: 80 }}
                        options={[
                            { value: '1분', label: '1분' },
                            { value: '5분', label: '5분' },
                            { value: '10분', label: '10분' },
                            { value: '15분', label: '15분' },
                            { value: '30분', label: '30분' },
                            { value: '60분', label: '60분' },
                        ]}
                    />
                    <Segmented
                        defaultValue="일"
                        options={[
                            { value: '일', label: '일' },
                            { value: '주', label: '주' },
                            { value: '월', label: '월' },
                            { value: '년', label: '년' }
                        ]}
                    />
                    <Select
                        value={chartType}
                        onChange={setChartType}
                        style={{ width: 100 }}
                        options={[
                            { value: '캔들', label: '캔들' },
                            { value: '라인', label: '라인' },
                        ]}
                    />
                </Space>
            </div>
            <div className="chart-component-wrapper">
                <ChartComponent data={initialData} chartType={chartType} />
            </div>
        </div>
    );
}

export default Chart;
