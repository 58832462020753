// 더미 즐겨찾기 데이터 생성 함수
const generateDummyFavorites = () => {
  const symbols = ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'TSLA', 'FB', 'NFLX', 'NVDA', 'JPM', 'V'];
  const favorites = [];

  symbols.forEach(symbol => {
    const currentPrice = parseFloat((Math.random() * 1000 + 50).toFixed(2));
    const priceChange = parseFloat((Math.random() * 20 - 10).toFixed(2));
    const priceChangePercent = parseFloat((priceChange / currentPrice * 100).toFixed(2));

    favorites.push({
      symbol,
      name: `${symbol} Corp`, // 실제로는 회사 이름 데이터가 필요합니다
      currentPrice,
      priceChange,
      priceChangePercent,
      priceChangeRange: {
        high: currentPrice + Math.abs(priceChange), // 현재 가격에 변화량을 더한 값
        low: currentPrice - Math.abs(priceChange)   // 현재 가격에서 변화량을 뺀 값
      }
    });
  });

  return favorites;
};

// 더미 데이터 생성 및 내보내기
export const dummyFavorites = generateDummyFavorites();

// 실시간 업데이트를 시뮬레이션하는 함수
export const updateFavorites = () => {
  return dummyFavorites.map(favorite => {
    const priceChange = favorite.currentPrice * (Math.random() * 0.02 - 0.01); 
    const newCurrentPrice = parseFloat((favorite.currentPrice + priceChange).toFixed(2)); 
    const newPriceChange = parseFloat((newCurrentPrice - favorite.currentPrice).toFixed(2)); 
    const newPriceChangePercent = parseFloat((newPriceChange / favorite.currentPrice * 100).toFixed(2));

    return {
      ...favorite,
      currentPrice: newCurrentPrice,
      priceChange: newPriceChange,
      priceChangePercent: newPriceChangePercent,
      priceChangeRange: {
        high: Math.max(favorite.priceChangeRange.high, newCurrentPrice),
        low: Math.min(favorite.priceChangeRange.low, newCurrentPrice)
      }
    };
  });
};

// import axios from 'axios';

// 서버로 즐겨찾기 순서 업데이트 요청을 보내는 함수
// export const updateFavoriteOrderOnServer = async (newOrder) => {
//   try {
//     const response = await axios.post('/api/favorites/update-order', { order: newOrder });
//     return response.data;
//   } catch (error) {
//     console.error('Failed to update favorite order:', error);
//     throw error;
//   }
// };
