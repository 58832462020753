const generateMarketData = () => {
  const symbols = ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'TSLA', 'FB', 'NFLX', 'NVDA', 'JPM', 'V', 'DIS', 'PYPL', 'ADBE', 'CRM', 'INTC', 'AMD', 'CSCO', 'ORCL', 'IBM', 'UBER'];
  return symbols.map(symbol => ({
    name: symbol,
    change: parseFloat((Math.random() * 10 - 5).toFixed(2)),
    price: parseFloat((Math.random() * 1000 + 50).toFixed(2))
  }));
};

export const marketData = generateMarketData();

export const updateMarketData = () => {
  return marketData.map(stock => ({
    ...stock,
    change: parseFloat((stock.change + (Math.random() * 2 - 1)).toFixed(2)),
    price: parseFloat((stock.price * (1 + stock.change / 100)).toFixed(2))
  }));
};

