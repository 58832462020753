import React from 'react';
import ReactGridLayout from 'react-grid-layout';
import './Test.scss';

const layouts = {
    lg: [
        { i: 'a', x: 0, y: 0, w: 2, h: 2 },
        { i: 'b', x: 2, y: 0, w: 2, h: 2 },
        { i: 'c', x: 4, y: 0, w: 2, h: 2 },
    ],
};

const Test = () => {
    return (
        <div className='Test'>
            
        </div>
    );
};

export default Test;