import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/AuthSlice';
import gridReducer from './slices/GridSlice';
import positionReducer from './slices/PositionsSlice';
import marketReducer from './slices/MarketSlice';
import favoriteReducer from './slices/FavoriteSlice';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    grid: gridReducer,
    positions: positionReducer,
    market: marketReducer,
    favorite: favoriteReducer,
  },
});

export default store;
