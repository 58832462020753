import React, { useState } from 'react';
import { Modal } from 'antd';
import './Footer.scss';

function Footer() {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-left">
                    <span>© 2024 ustock</span>
                </div>
                <div className="footer-right">
                    <a href="/privacy">개인정보처리방침</a>
                    <a href="/terms">이용약관</a>
                    <a onClick={showModal}>Founder's Statement</a>
                </div>
            </div>
            <Modal
                // title="Founder's Statement"
                open={isModalVisible} 
                onCancel={handleCancel}
                footer={null}
                width="80%"
                styles={{
                    content: {
                        // top: '5vh',
                        height: '80vh',
                        paddingBottom: 0,
                        borderRadius: 0
                    },
                    body: {
                        height: '100%',
                        // overflow: 'auto'
                    }
                }}
                className="founder-modal"
            >
                <div className="founder-statement">
                    <h1>Founder's Statement</h1>
                    <p>우리는 지금 인류 역사상 가장 중요한 시기에 살고 있습니다. 우리의 행동이 지구의 미래를 결정할 것입니다.</p>
                    <p><strong>지속 가능한 에너지로의 전환은 더 이상 선택이 아닌 필수입니다.</strong> 우리는 화석 연료에 대한 의존도를 줄이고, 재생 에너지 기술을 발전시켜야 합니다.</p>
                    <p>우리의 목표는 단순히 전기차를 만드는 것이 아닙니다. <strong>우리의 진정한 목표는 지속 가능한 에너지 생태계를 구축하는 것입니다.</strong> 태양광 패널, 에너지 저장 시스템, 그리고 전기차가 하나로 연결된 미래를 만들어 나가고 있습니다.</p>
                    <p>인공지능과 자율주행 기술은 우리의 삶을 근본적으로 변화시킬 것입니다. <strong>이 기술들은 교통사고를 줄이고, 도시의 교통 체증을 해소하며, 더 많은 사람들에게 이동의 자유를 줄 것입니다.</strong></p>
                    <p>우주 탐사는 인류의 미래를 위한 보험입니다. 우리는 멀티 플래닛 종이 되어야 합니다. <strong>화성 식민지 건설은 단순한 꿈이 아닌, 우리가 반드시 이뤄내야 할 목표입니다.</strong></p>
                    <p>하이퍼루프와 같은 혁신적인 교통 수단은 세계를 더 가깝게 만들 것입니다. 도시간 이동 시간을 획기적으로 줄임으로써, 우리는 더 많은 기회를 얻게 될 것입니다.</p>
                    <p><strong>우리의 목표는 인류를 위협하는 실존적 위험을 줄이고, 의식의 범위를 확장하는 것입니다.</strong> 이를 위해 우리는 끊임없이 혁신하고, 도전하며, 때로는 불가능해 보이는 일에 도전할 것입니다.</p>
                    <p>우리는 실패를 두려워하지 않습니다. <strong>실패는 혁신의 과정에서 필연적으로 겪게 되는 것이며, 우리를 더 강하게 만들어 줄 것입니다.</strong></p>
                    <p>우리의 여정은 쉽지 않을 것입니다. 하지만 우리가 함께한다면, 불가능은 없습니다. <strong>함께 더 나은 미래를 만들어 갑시다.</strong></p>
                </div>
            </Modal>
        </footer>
    );
}

export default Footer;
