import React from 'react';

// 개별 포지션 아이템 컴포넌트
const PositionItem = ({ position, style }) => {
  const isProfitable = position.evaluationProfit >= 0;

  // 숫자 포맷팅 함수
  const formatNumber = (num, decimalPlaces = 0) => {
    return num.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <div 
        className="position-item"
        style={style}
      >
        {/* 수익/손실 표시 막대 */}
        <div className="profit-bar" style={{
          backgroundColor: isProfitable ? 'rgb(0, 200, 0)' : 'red',
          height: '95%'
        }}></div>
        {/* 포지션 정보 표시 */}
        <span>{position.weight}%</span>
        <span>{position.name}</span>
        <span>{formatNumber(position.evaluationAmount)} 원</span>
        <span className={isProfitable ? 'profit' : 'loss'}>
          {formatNumber(position.evaluationProfit)} 원
        </span>
        <span>{formatNumber(position.purchaseAmount)} 원</span>
        <span>{formatNumber(position.entryPrice, 2)} 원</span>
        <span>{formatNumber(position.currentPrice, 2)} 원</span>
        <span>{formatNumber(position.quantity)}</span>
        <span className={isProfitable ? 'profit' : 'loss'}>
          {position.profitRate}%
        </span>
      </div>
      <div className="position-divider"></div>
    </>
  );
};

export default PositionItem;
