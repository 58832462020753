// 더미 포지션 데이터 생성 함수
const generateDummyPositions = () => {
  const symbols = ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'TSLA', 'FB', 'NFLX', 'NVDA', 'JPM', 'V', 'DIS', 'PYPL', 'ADBE', 'CRM', 'INTC'];
  const positions = [];

  symbols.forEach(symbol => {
    const quantity = Math.floor(Math.random() * 1000) + 1;
    const entryPrice = parseFloat((Math.random() * 1000 + 50).toFixed(2));
    const currentPrice = parseFloat((entryPrice * (1 + (Math.random() * 0.2 - 0.1))).toFixed(2));
    const evaluationAmount = quantity * currentPrice;
    const purchaseAmount = quantity * entryPrice;
    const evaluationProfit = evaluationAmount - purchaseAmount;
    const profitRate = ((currentPrice - entryPrice) / entryPrice * 100).toFixed(2);

    positions.push({
      symbol,
      name: `${symbol}`, // 실제로는 회사 이름 데이터가 필요합니다
      quantity,
      entryPrice,
      currentPrice,
      evaluationAmount,
      purchaseAmount,
      evaluationProfit,
      profitRate,
      weight: 0 // 나중에 계산됩니다
    });
  });

  const totalValue = positions.reduce((sum, pos) => sum + pos.evaluationAmount, 0);
  positions.forEach(pos => {
    pos.weight = ((pos.evaluationAmount / totalValue) * 100).toFixed(2);
  });

  return positions.sort((a, b) => b.evaluationAmount - a.evaluationAmount);
};

// 더미 데이터 생성 및 전역 변수에 할당
export const dummyPositions = generateDummyPositions();

// 실시간 업데이트를 시뮬레이션하는 함수
export const updatePositions = () => {
  return dummyPositions.map(position => {
    const priceChange = position.currentPrice * (Math.random() * 0.02 - 0.01);
    const newCurrentPrice = parseFloat((position.currentPrice + priceChange).toFixed(2));
    const newEvaluationAmount = position.quantity * newCurrentPrice;
    const newEvaluationProfit = newEvaluationAmount - position.purchaseAmount;
    const newProfitRate = ((newCurrentPrice - position.entryPrice) / position.entryPrice * 100).toFixed(2);

    return {
      ...position,
      currentPrice: newCurrentPrice,
      evaluationAmount: newEvaluationAmount,
      evaluationProfit: newEvaluationProfit,
      profitRate: newProfitRate
    };
  });
};
