import React from 'react';
import { FaBars } from 'react-icons/fa';
import { useDrag, useDrop } from 'react-dnd';

const ItemType = {
  FAVORITE: 'favorite',
};

const FavoriteItem = ({ item, index, moveItem }) => {
  const ref = React.useRef(null);
  const handleRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemType.FAVORITE,
    hover(draggedItem) {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.FAVORITE,
    item: { type: ItemType.FAVORITE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(ref);
  drag(handleRef);

  return (
    <div
      ref={ref}
      className={`favorite-item ${isDragging ? 'dragging' : ''}`}
    >
      <div className="candle-wrapper">
        <div className="candle-wick" style={{
          backgroundColor: item.priceChange >= 0 ? 'green' : 'red',
          height: '20px',
        }}></div>
        <div className="candle-body" style={{
          backgroundColor: item.priceChange >= 0 ? 'green' : 'red',
          top: '0px',
          height: '10px',
        }}></div>
      </div>
      <span className="stock-name">{item.name}</span>
      <div className="price-info">
        <span className="current-price">{item.currentPrice.toLocaleString()}</span>
        <span className={`price-change ${item.priceChange >= 0 ? 'positive' : 'negative'}`}>
          <span className="change-arrow">{item.priceChange >= 0 ? '▲' : '▼'}</span>
          <span className="change-amount">{item.priceChange >= 0 ? '' : '-'}{Math.abs(item.priceChange).toFixed(2)}</span>
          <span className="change-percent">{item.priceChangePercent >= 0 ? '' : '-'}{Math.abs(item.priceChangePercent).toFixed(2)}%</span>
        </span>
      </div>
      <div ref={handleRef} className="handle-container">
        <FaBars className="handle-icon" />
      </div>
    </div>
  );
};

export default FavoriteItem;
