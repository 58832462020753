import React, { useState, useEffect, useRef, useCallback } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

// 그리드 디자인 수정
import "./css/_grid_style.css";
import "./css/grid_style.scss";
import "./css/_resizeable_style.css";

import gridProps from "./Settings.js";
import Layouts from "./Layouts.js";

// 컴포넌트 임포트
import Info from "../../widgets/Info/Info";
import Sign from "../../widgets/Sign/Sign";
import Chart from "../../widgets/Chart/Chart";
import Subinfo from "../../widgets/Subinfo/Subinfo";
import Favorite from "../../widgets/favorite/Favorite";
import Position from "../../widgets/Position/Position";
import Account from "../../widgets/Account/Account";
import Trading from "../../widgets/Trading/Trading";

const GridLayout = WidthProvider(Responsive);

function Grid() {
  const [layout, setLayout] = useState(Layouts.lg);
  const [gridDimensions, setGridDimensions] = useState({ width: 0, height: 0 });
  const [dynamicRowHeight, setDynamicRowHeight] = useState(30);
  const [customHandles, setCustomHandles] = useState({});
  const gridRef = useRef(null);

  const calculateRowHeight = useCallback((height) => {
    return (height -5) / 30;
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        const newRowHeight = calculateRowHeight(height);
        setGridDimensions({ width, height });
        setDynamicRowHeight(newRowHeight);
        console.log("Totalheight:", height);
        console.log("Cell Dynamic Height:", newRowHeight);
        console.log("")
      }
    });

    if (gridRef.current) {
      resizeObserver.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        resizeObserver.unobserve(gridRef.current);
      }
    };
  }, [calculateRowHeight]);

  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const renderComponent = useCallback((item) => {
    const components = {
      'a': { component: Info, name: '<정보>' },
      'b': { component: Sign, name: '<전광판>' },
      'c': { component: Chart, name: '<차트>' },
      // 'd': { component: Subinfo, name: '<정보>' },
      'd': { component: Favorite, name: '<즐겨찾기>' },
      'e': { component: Trading, name: '<트레이딩>' },
      'f': { component: Position, name: '<포지션>' },
      'g': { component: Account, name: '<계좌>' },
    };

    const ComponentToRender = components[item.i]?.component || null;
    const customHandleClass = `custom-handle-${item.i}`;
    
    return {
      component: ComponentToRender ? (
        <ComponentToRender 
          customDraggableHandle={customHandleClass} 
          onCustomHandleRender={() => setCustomHandles(prev => ({ ...prev, [item.i]: true }))}
        />
      ) : null,
      name: components[item.i]?.name || 'Unknown',
      customHandleClass
    };
  }, []);

  return (
    <div ref={gridRef} style={{ height: '100%' }}>
      <GridLayout
        {...gridProps}
        layouts={Layouts}
        onLayoutChange={onLayoutChange}
        draggableHandle={`.drag-handle, [class^="custom-handle-"]`}
        rowHeight={dynamicRowHeight}
        style={{ height: '100%' }}
      >
        {layout.map((item) => {
          const { component, name, customHandleClass } = renderComponent(item);
          const itemHeight = item.h * dynamicRowHeight;

          return (
            <div key={item.i} className="grid-item">
              {!customHandles[item.i] && (
                <div className="drag-handle" style={{ zIndex: 1 }}>
                  <span className="drag-handle-text">{name}</span>
                  <span className="item-info">
                    {/* {`${item.i.toUpperCase()} | W,H:${item.w}x${item.h} | X,Y:${item.x},${item.y} | ${itemHeight.toFixed(2)}px`} */}
                  </span>
                </div>
              )}
              <div className="component-container" style={{ zIndex: 0 }}>
                {component}
              </div>
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
}

export default Grid;
