import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dummyFavorites, updateFavorites } from '../../../widgets/favorite/Data';
// import { updateFavoriteOrderOnServer } from '../../../widgets/favorite/Data';

// 즐겨찾기 데이터를 가져오는 비동기 액션
export const fetchFavorites = createAsyncThunk(
  'favorite/fetchFavorites',
  async () => {
    // 실제 API 호출 대신 더미 데이터 사용
    return dummyFavorites;
  }
);

// 즐겨찾기 순서를 업데이트하는 비동기 액션
export const updateFavoriteOrder = createAsyncThunk(
  'favorite/updateFavoriteOrder',
  async (newOrder, { rejectWithValue }) => {
    try {
      // 서버 업데이트 함수 호출 (주석 처리됨)
      // await updateFavoriteOrderOnServer(newOrder);
      return newOrder;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: {
    favorites: dummyFavorites,
    isLoading: false,
    error: null,
  },
  reducers: {
    updateFavoriteData: (state) => {
      state.favorites = updateFavorites();
    },
    updateFavoriteOrderLocal: (state, action) => {
      state.favorites = action.payload;
      // console.log(state.favorites);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.isLoading = false;
        state.favorites = action.payload;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateFavoriteOrder.fulfilled, (state, action) => {
        state.favorites = action.payload;
      });
  },
});

export const { updateFavoriteData, updateFavoriteOrderLocal } = favoriteSlice.actions;

export default favoriteSlice.reducer;
