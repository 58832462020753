import React from 'react';

const Tab2 = () => {
  return (
    <div className="tab2-container">
      <h2>거래내역</h2>
      {/* 여기에 해외 포지션 관련 컴포넌트를 추가하세요 */}
    </div>
  );
};

export default Tab2;

