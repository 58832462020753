// grid_Setting.js
// import layouts from "./Layouts.js"

const gridProps = {
  className: "layout", // 그리드 레이아웃의 클래스 이름
  // layouts: layouts, // 각 브레이크포인트에 대한 레이아웃 정의
  breakpoints: { 
    xxl: 1600, 
    xl: 1400, 
    lg: 1200, 
    md: 992, 
    sm: 768, 
    xs: 576, 
    xxs: 375, 
    xxxs: 0 
  }, // 반응형 브레이크포인트 정의 (최대한 세분화)
  cols: { 
    xxl: 48, 
    xl: 40, 
    lg: 32, 
    md: 24, 
    sm: 16, 
    xs: 12, 
    xxs: 8, 
    xxxs: 4 
  }, // 각 브레이크포인트에 대한 열 수
  // rowHeight: 30, // 행의 높이 (픽셀)
  autoSize: true, // 이 부분을 true로 변경
  // containerPadding: [0, 0], // 컨테이너의 패딩 [x, y]
  margin: [5, 5], // 아이템 간 마진 [x, y]
  // useCSSTransforms: true, // CSS 변환 사용 여부
  verticalCompact: null, // 수직 압축 여부
  // verticalCompact: true, // 수직 압축 여부
  // compactType: "vertical", // 압축 방향 (위쪽으로)
  compactType: null, // 압축 방향 (위쪽으로)
  preventCollision: true, // 충돌 방지 여부
  // isResizable: true, // 크기 조정 가능 여부
  // isBounded: true, // 아이템이 그리드 밖으로 나가지 않도록 제한
  // isDraggable: true, // 아이템 드래그 가능 여부
  // isDroppable: false, // 외부 요소를 그리드에 드롭 가능 여부
  // transformScale: 1, // 그리드 전체 스케일 조정
  // onLayoutChange: (layout, layouts) => {}, // 레이아웃 변경 시 호출되는 콜백 함수
  // onBreakpointChange: (newBreakpoint, newCols) => {}, // 브레이크포인트 변경 시 호출되는 콜백 함수
  // onDragStart: (layout, oldItem, newItem, placeholder, e, element) => {}, // 드래그 시작 시 호출되는 콜백 함수
  // onDrag: (layout, oldItem, newItem, placeholder, e, element) => {}, // 드래그 중 호출되는 콜백 함수
  // onDragStop: (layout, oldItem, newItem, placeholder, e, element) => {}, // 드래그 종료 시 호출되는 콜백 함수
  // onResizeStart: (layout, oldItem, newItem, placeholder, e, element) => {}, // 리사이즈 시작 시 호출되는 콜백 함수
  // onResize: (layout, oldItem, newItem, placeholder, e, element) => {}, // 리사이즈 중 호출되는 콜백 함수
  // onResizeStop: (layout, oldItem, newItem, placeholder, e, element) => {}, // 리사이즈 종료 시 호출되는 콜백 함수
};

export default gridProps;
