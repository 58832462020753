import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      // 더미 응답 데이터. 실제 서버 통신 시 이 부분을 API 호출로 대체해야 함
      const dummyResponse = {
        user: { id: 1, username: username },
        token: 'dummy_token'
      };
      // 토큰을 로컬 스토리지에 저장. 실제 구현 시 보안을 고려해야 함
      localStorage.setItem('token', dummyResponse.token);
      return dummyResponse;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: localStorage.getItem('token'),
    isLoading: false,
    error: null,
    isLoggedIn: false,
    name: '',
    id: '',
  },
  reducers: {
    logout: (state) => {
      // 로그아웃 시 토큰 제거. 실제 구현 시 서버와의 통신도 고려해야 함
      localStorage.removeItem('token');
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        // 로그인 성공 시 사용자 정보와 토큰을 저장
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, setIsLoggedIn, setName, setId } = AuthSlice.actions;
export default AuthSlice.reducer;