const initialData = [];

const startDate = new Date('2021-01-01');
const endDate = new Date('2023-12-31');
let currentDate = new Date(startDate);
let baseValue = 100;
let monthTrend = 0;

while (currentDate <= endDate) {
  if (currentDate.getDate() === 1) {
    monthTrend = (Math.random() - 0.5) * 20;
  }

  const dailyChange = (Math.random() - 0.5) * 4;
  baseValue += baseValue * (monthTrend / 100 / 30 + dailyChange / 100);

  const open = baseValue;
  const high = open * (1 + Math.random() * 0.02);
  const low = open * (1 - Math.random() * 0.02);
  const close = (open + high + low) / 3;

  initialData.push({
    time: currentDate.toISOString().split('T')[0],
    open: parseFloat(open.toFixed(2)),
    high: parseFloat(high.toFixed(2)),
    low: parseFloat(low.toFixed(2)),
    close: parseFloat(close.toFixed(2)),
    value: parseFloat(close.toFixed(2))
  });

  currentDate.setDate(currentDate.getDate() + 1);
}

export default initialData;