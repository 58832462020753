import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import axios from 'axios';
import { FaUpload } from 'react-icons/fa';
import FirstPage from './FirstPage';
import LoadingIndicator from './LoadingIndicator';
import CreatorProfile from './CreatorProfile';
import './Add.scss';

const Add = ({ isVisible, onClose }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [creatorData, setCreatorData] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [showTestButtons, setShowTestButtons] = useState(true);

  const handleSearch = async () => {
    setIsLoading(true);
    // API 호출 (주석 처리)
    /*
    try {
      const response = await axios.get(`/api/creator?search=${searchValue}`);
      setCreatorData(response.data);
      setIsRegistered(response.data.isRegistered);
    } catch (error) {
      console.error('Error fetching creator data:', error);
    }
    */
    // 임시 데이터 사용
    setTimeout(() => {
      setCreatorData({
        name: '테스트 크리에이터',
        profileImage: 'https://yt3.googleusercontent.com/ytc/AIdro_kutxZhVtnH4nWcW7ebuDER5TfHwPZJaqGyBVGjVC52A0A=s160-c-k-c0x00ffffff-no-rj'
      });
      setIsLoading(false);
    }, 2000);
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width="80%"
      styles={{
        body: {
          height: '80vh',
          padding: 0,
        }
      }}
      className="add-modal"
    >
      
      
      {/* 로딩 중이 아니고 크리에이터 데이터가 없을 때 FirstPage 컴포넌트를 렌더링 */}
      {!isLoading && !creatorData && ( 
        <FirstPage
          value={searchValue}
          onChange={setSearchValue}
          onSearch={handleSearch}
        />
      )}
      
      {isLoading && <LoadingIndicator />}
      
      {/* 크리에이터 프로필 */}
      {creatorData && (
        <CreatorProfile
          data={creatorData}
          isRegistered={isRegistered}
        />
      )}

      {/* 테스트 버튼 */}
      {showTestButtons && (
        <div className="test-buttons">
          <button onClick={() => setIsRegistered(true)}>등록된 경우</button>
          <button onClick={() => setIsRegistered(false)}>미등록 경우</button>
        </div>
      )}
    </Modal>
  );
};

export default Add;
